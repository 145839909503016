var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch","atid":"targeted_edit_view"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("abTestConfig.editConfigsTitle", _vm.initialAbTestConfig.name))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"to":{ name: _vm.AppSection.AB_TESTS }}},[_vm._v(" "+_vm._s(_vm.$lang("btn.toTheList"))+" ")]),(_vm.fromRoute && _vm.fromRoute.name === 'abTest_view')?_c('v-btn',{staticClass:"ml-3",attrs:{"to":{ name: _vm.fromRoute.name }}},[_vm._v(" "+_vm._s(_vm.$lang("btn.back"))+" ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.selectedSegment)?_c('v-tabs',{staticClass:"mt-3",attrs:{"vertical":""},on:{"change":_vm.handleChangeTab},model:{value:(_vm.activeSegmentTab),callback:function ($$v) {_vm.activeSegmentTab=$$v},expression:"activeSegmentTab"}},[_vm._l((_vm.abTestConfig.responses),function(response){return _c('v-tab',{key:response.id,staticStyle:{"width":"200px"},attrs:{"href":("#" + (response.segmentName))}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-ellipsis--custom"},on),[_vm._v(" "+_vm._s(response.segmentName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(response.segmentName))])])],1)}),_c('v-tabs-items',{model:{value:(_vm.currentSegmentTab),callback:function ($$v) {_vm.currentSegmentTab=$$v},expression:"currentSegmentTab"}},[_c('v-tab-item',{attrs:{"value":_vm.activeSegmentTab}},[_c('v-tabs',{style:([
                    {
                      borderLeft: _vm.$vuetify.theme.dark
                        ? 'thin solid rgba(255, 255, 255, 0.12)'
                        : 'thin solid rgba(0, 0, 0, 0.12)',
                    } ]),attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#tab-stage"}},[(
                        _vm.ConfigUtil.hasDiffBetweenConfigs(
                          _vm.originConfigResponse.stage.response,
                          _vm.selectedSegment.stage.response
                        )
                      )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.stage"))+" ")])]),_c('v-tab',{attrs:{"href":"#tab-production"}},[(
                        _vm.ConfigUtil.hasDiffBetweenConfigs(
                          _vm.originConfigResponse.production.response,
                          _vm.selectedSegment.production.response
                        )
                      )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.production"))+" ")])]),_c('v-tab-item',{attrs:{"value":"tab-stage"}},[_c('config-response-editor',{attrs:{"value":_vm.selectedSegment.stage.response,"is-deployer-visible":true,"active-tab":_vm.activeTab,"default-config-response":_vm.selectedSegment,"initial-config":_vm.originConfigResponse,"ab-test-responses":_vm.abTestConfig.responses,"editable":_vm.abTestConfig.editable,"config-parent-id":_vm.abTestConfig.parentId,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                          _vm.originConfigResponse.stage.response,
                          _vm.selectedSegment.stage.response
                        ),"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.stage,"has-deploy-access":_vm.abTestConfig.hasDeployAccess},on:{"save":_vm.setConfig,"deploy":_vm.deployToStage,"import":_vm.handleImportToStage,"transfer":_vm.transferToProd,"copyResponse":_vm.handleCopyResponse}})],1),_c('v-tab-item',{attrs:{"value":"tab-production"}},[_c('config-response-editor',{attrs:{"value":_vm.selectedSegment.production.response,"active-tab":_vm.activeTab,"is-deployer-visible":true,"default-config-response":_vm.selectedSegment,"initial-config":_vm.originConfigResponse,"ab-test-responses":_vm.abTestConfig.responses,"editable":_vm.abTestConfig.editable,"config-parent-id":_vm.abTestConfig.parentId,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                          _vm.originConfigResponse.production.response,
                          _vm.selectedSegment.production.response
                        ),"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.production,"has-deploy-access":_vm.abTestConfig.hasDeployAccess},on:{"save":_vm.setConfig,"deploy":_vm.deployToProd,"transfer":_vm.transferToStage,"copyResponse":_vm.handleCopyResponse}})],1)],1)],1)],1)],2):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"400px"},model:{value:(_vm.isUnDeployedDialog),callback:function ($$v) {_vm.isUnDeployedDialog=$$v},expression:"isUnDeployedDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"color":"primary","dark":"","dense":""}},[_vm._v(" "+_vm._s(_vm.$lang("shared.applicationDialog.attentionTitle"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.notDeployedChanges"))+" ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","dense":""},on:{"click":function($event){_vm.isUnDeployedDialog = false}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.cancel"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }