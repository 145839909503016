





















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Route, NavigationGuardNext } from "vue-router";
import { cloneDeep } from "lodash";

import ConfigResponseEditor from "@/ab-tests/components/ConfigResponseEditor.vue";
import AbTestConfigurationModel from "@/ab-tests/models/AbTestConfigurationModel";
import { AppSection } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import AbTestResponseModel from "@/ab-tests/models/AbTestResponseModel";
import ApplicationResponseModel, {
  EnvironmentModel,
  EnvironmentType,
} from "@/ab-tests/models/ApplicationResponseModel";
import ItemPreviewModel, {
  PREVIEW_ITEM_TYPE,
} from "@/ab-tests/models/ItemPreviewModel";
import JsonUtil from "@/ab-tests/utils/JsonUtil";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component({
  components: {
    ConfigResponseEditor,
  },
})
export default class AbTestConfigurationEditConfigsView extends mixins(
  UnsavedChangesMixin
) {
  readonly ConfigUtil = ConfigUtil;
  readonly AppSection = AppSection;
  abTestConfig: AbTestConfigurationModel = new AbTestConfigurationModel();
  isWatchedAbTest = false;
  isUnDeployedDialog = false;
  activeTab = "";
  activeSegmentTab = "";
  currentSegmentTab = "";
  selectedSegment: AbTestResponseModel | null = null;
  fromRoute: Route | null = null;
  previewItemsAfterTransfer: Record<string, Array<ItemPreviewModel>> = {
    stage: [],
    production: [],
  };

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.abTestConfig);
  }

  get originConfigResponse(): any {
    return this.initialAbTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );
  }

  get hasDuplicateConfigs(): boolean {
    const configs = this.abTestConfig.responses.map((it) => it.stage.response);
    return new Set(configs).size !== configs.length;
  }

  get isLoading(): boolean {
    return (
      this.$store.state.abTestConfig.loadingConfig ||
      this.$store.state.configsStore.isLoadingConfigsDiff
    );
  }

  get configId(): number {
    return Number.parseInt(this.$route.params.configId);
  }

  get isDeploymentInProgress(): boolean {
    return this.$store.state.targetedConfig.deploymentInProgress;
  }

  get initialAbTestConfig(): AbTestConfigurationModel {
    return this.$store.state.abTestConfig.abTestConfig;
  }

  @Watch("configId", { immediate: true })
  async watchConfigId(configId: number) {
    await this.$store.dispatch("getAbTestConfig", configId);
  }

  @Watch("initialAbTestConfig", { deep: true })
  private watchAbTestConfig(abTestConfig: AbTestConfigurationModel) {
    this.abTestConfig = AbTestConfigurationModel.of(abTestConfig);

    if (this.$route.query?.segmentName) {
      this.activeSegmentTab = String(this.$route.query.segmentName);
      this.currentSegmentTab = String(this.$route.query.segmentName);
      const found = this.abTestConfig.responses.find(
        (response) =>
          response.segmentName === String(this.$route.query.segmentName)
      );
      if (found) {
        this.selectedSegment = cloneDeep(found);
      }
    } else {
      this.activeSegmentTab = String(
        this.abTestConfig.responses[0].segmentName
      );
      this.currentSegmentTab = String(
        this.abTestConfig.responses[0].segmentName
      );
      this.selectedSegment = this.abTestConfig.responses[0];
      this.$router.replace({
        query: {
          segmentName: this.activeSegmentTab,
        },
      });
    }

    if (!this.isWatchedAbTest) {
      this.isWatchedAbTest = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: Vue & { fromRoute?: Route }) => {
      vm.fromRoute = from;
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleChangeTab(value: string) {
    if (
      this.selectedSegment &&
      !this.isSavedForm &&
      (ConfigUtil.hasDiffBetweenConfigs(
        this.originConfigResponse.stage.response,
        this.selectedSegment.stage.response
      ) ||
        ConfigUtil.hasDiffBetweenConfigs(
          this.originConfigResponse.production.response,
          this.selectedSegment.production.response
        ))
    ) {
      this.isUnDeployedDialog = true;
      this.$nextTick(() => {
        this.activeSegmentTab = this.currentSegmentTab;
      });
    } else {
      this.currentSegmentTab = this.activeSegmentTab;
      const found = this.abTestConfig.responses.find(
        (response) => response.segmentName === value
      );

      if (found) {
        this.selectedSegment = cloneDeep(found);
      }

      this.$router.replace({
        query: {
          segmentName: value,
        },
      });
    }
  }

  setConfig(val: string) {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      if (this.activeTab === "tab-stage") {
        found.stage.response = val;
        if (this.selectedSegment) this.selectedSegment.stage.response = val;
      }

      if (this.activeTab === "tab-production") {
        found.production.response = val;
        if (this.selectedSegment)
          this.selectedSegment.production.response = val;
      }

      this.isSavedForm = false;
    }
  }

  async deployToProd(isDeployAndSync: boolean) {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      if (isDeployAndSync) {
        found.stage.response = found.production.response;
      }

      const config = this.abTestConfig;
      await this.$store.dispatch("deployAbTestConfigResponse", {
        config,
        response: found,
        environmentType: EnvironmentType.PROD,
      });

      if (isDeployAndSync) {
        await this.$store.dispatch("deployAbTestConfigResponse", {
          config,
          response: found,
          environmentType: EnvironmentType.STAGE,
        });
      }

      this.resetPreviewItemsData();
      this.isSavedForm = true;
    }
  }

  async deployToStage(isDeployAndSync: boolean) {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      if (isDeployAndSync) {
        found.production.response = found.stage.response;
      }

      const config = this.abTestConfig;
      await this.$store.dispatch("deployAbTestConfigResponse", {
        config,
        response: found,
        environmentType: EnvironmentType.STAGE,
      });

      if (isDeployAndSync) {
        await this.$store.dispatch("deployAbTestConfigResponse", {
          config,
          response: found,
          environmentType: EnvironmentType.PROD,
        });
      }

      this.resetPreviewItemsData();
      this.isSavedForm = true;
    }
  }

  transferToProd() {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      found.production.response = found.stage.response;
      this.selectedSegment = found;
      this.fillPreviewItemsAfterTransfer("production", "stage");
      this.activeTab = "tab-production";
      this.isSavedForm = false;
    }
  }

  transferToStage() {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      found.stage.response = found.production.response;
      this.selectedSegment = found;
      this.fillPreviewItemsAfterTransfer("stage", "production");
      this.activeTab = "tab-stage";
      this.isSavedForm = false;
    }
  }

  fillPreviewItemsAfterTransfer(to: string, from: string) {
    this.previewItemsAfterTransfer[to] = [
      ...JsonUtil.formattingFromJson(
        this.originConfigResponse[to as "stage" | "production"].response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            (this.selectedSegment as AbTestResponseModel)[
              from as "stage" | "production"
            ].response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.DELETED,
        })),
      ...JsonUtil.formattingFromJson(
        (this.selectedSegment as AbTestResponseModel)[
          from as "stage" | "production"
        ].response
      )
        .filter(({ key, value }: { key: string; value: string }) =>
          JsonUtil.formattingFromJson(
            this.originConfigResponse[to as "stage" | "production"].response
          ).some((item: any) => item.key === key && item.value !== value)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.EDITED,
        })),
      ...JsonUtil.formattingFromJson(
        (this.selectedSegment as AbTestResponseModel)[
          from as "stage" | "production"
        ].response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            this.originConfigResponse[to as "stage" | "production"].response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.ADDED,
        })),
    ];
  }

  resetPreviewItemsData() {
    this.previewItemsAfterTransfer = {
      stage: [],
      production: [],
    };
  }

  handleImportToStage(value: string) {
    if (this.selectedSegment) {
      const found = this.abTestConfig.responses.find(
        (response) => response.id === this.selectedSegment?.id
      );

      if (found) {
        found.stage.response = value;
        this.selectedSegment.stage.response = value;
      }
    }
  }

  handleCopyResponse({
    payload,
    field,
  }: {
    payload: AbTestResponseModel | ApplicationResponseModel;
    field: "stage" | "production";
  }) {
    const found = this.abTestConfig.responses.find(
      (response) => response.id === this.selectedSegment?.id
    );

    if (found) {
      if (this.activeTab === "tab-stage") {
        found.stage.response = (payload[field] as EnvironmentModel).response;
      } else if (this.activeTab === "tab-production") {
        found.production.response = (
          payload[field] as EnvironmentModel
        ).response;
      }

      this.selectedSegment = cloneDeep(found);
    }
  }
}
